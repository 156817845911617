<template>
  <div class="letter-of-authorization">
    <div class="main">
      <div class="top">
        <div class="title">授权书</div>
        <div class="no">授权编号: SQ20211122122200001</div>
      </div>
      <div class="content">
        <span class="name">王小锤:</span>
        <span class="description">
          根据《中华人民共和国著作权法》的相关规定，将本人作品《XXXXXX》
          （电子数据指纹Asdfasdferq5a45sdg542345srtwtr）的信息网络传播
          权授权给你。授权使用费为XX字符/XX元。授权期限为长期。授权使用
          范围为XXXXXXX。
        </span>
        <span class="end">特此授权！</span>
        <span class="sign">
          <span class="sign-name">授权人: <span>王大锤（手写签名）</span></span>
          <span class="time">2022年1月13日</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LetterOfAuthorization",
};
</script>

<style lang="scss" scoped>
.letter-of-authorization {
  height: 1080px;
  .main {
    @include noselect;
    width: 800px;
    height: 100%;
    margin: 0 auto;
    background: url("~assets/images/letter_of_authorization.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .top {
      @include flex-center(column);
      width: 300px;
      margin: 200px auto 0;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #000000;
      }
      .no {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #999999;
      }
    }
    .content {
      @include flex-center(column);
      align-items: normal;
      width: 600px;
      margin: 61px auto 0;
      .name {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
      .description {
        margin-top: 10px;
        line-height: 35px;
        text-indent: 35px;
        color: #666;
      }
      .end {
        margin-top: 10px;
        text-indent: 35px;
        color: #666;
      }
      .sign {
        @include flex-center(column);
        align-items: normal;
        margin-top: 180px;
        align-self: flex-end;
        color: #666;
        .time {
          align-self: center;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
